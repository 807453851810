import BaseProps from './props.base.ts';

export default {
    ...BaseProps,
    currentRow:{
        type:Object,
        required:false
    },
    title: {
        type: String,
        required: false
    },
    contextField: {
        type: [String, Function],
        required: false
    },
};
